<template>
  <div
    class="uppercase p-0.5 px-1.5 font-inter font-light not-italic text-base text-center cursor-pointer hover:opacity-90 border border-solid"
    :class="
      !!active
        ? 'bg-gray-600 text-white border-gray-600'
        : 'bg-letter-button text-light-gray border-letter-button'
    "
  >
    {{ letter }}
  </div>
</template>

<script>
export default {
  props: {
    letter: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
