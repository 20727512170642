<template>
  <Layout mainClasses="pt-1 px-5 bg-app-gray">
    <h1 class="text-left my-5 text-3xl font-body">
      {{ type?.name || "Tariff SMS" }}
    </h1>

    <div class="flex mb-4 mt-4 items-center gap-x-6">
      <div class="relative">
        <input
          type="search"
          v-model="search"
          :placeholder="
            $t('placeholder.widget.pages.tariffs.search_by_tariffs')
          "
          class="p-0.5 border border-app-purple pr-2 outline-none"
        />
        <Icon
          name="search"
          class="absolute top-1/2 right-0 translate"
          style="transform: translate(-35%, -40%)"
        />
      </div>
      <div class="flex gap-x-0.5 items-center flex-wrap w-full">
        <Letter
          v-for="letter in letters"
          :key="letter"
          :letter="letter"
          :active="isActive(letter)"
          @click.prevent="activeLetter = letter"
        />
        <div
          class="hover:opacity-90 cursor-pointer ml-auto"
          :title="$t('text.app.common.close')"
          @click.prevent="activeLetter = ''"
          v-show="activeLetter != ''"
        >
          <Icon name="times" color="#000" />
        </div>
      </div>
    </div>

    <div class="stats-table">
      <TableLoader v-if="loading" :rowCount="5" :colCount="3" />

      <table
        class="table-auto border-collapse w-full"
        v-else-if="!loading && filteredTariffs.length"
      >
        <thead
          class="text-app-purple text-xl font-inter font-normal border-0 border-b-2 border-app-purple p-2 pb-3 text-left"
        >
          <tr>
            <td>{{ $t("text.widget.pages.tariffs.destination") }}</td>
            <td>{{ $t("text.widget.pages.tariffs.dialing_code") }}</td>
            <td>{{ $t("text.widget.pages.tariffs.price") }}</td>
          </tr>
        </thead>
        <tbody class="stats-table-body">
          <tr v-for="tarrif in filteredTariffs" :key="tarrif.id">
            <td class="font-bold">{{ tarrif.destination }}</td>
            <td>{{ tarrif.dialing_code }}</td>
            <td>{{ tarrif.price }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </Layout>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import TableLoader from "./Reports/TableLoader";
import Layout from "../components/home/Layout/Index";
import useTariffs from "@/modules/widget/composables/useTariffs";
import Letter from "@/modules/widget/components/pricesAndTariffs/LetterButton";

const props = defineProps({
  id: {
    required: true,
    type: String,
  },
});

const { getTariffs } = useTariffs();
const activeLetter = ref("");
const search = ref("");

const route = useRoute();
const store = useStore();

const tariffs = computed(() => store.getters["tariffs/tariffs"]);
const types = computed(() => store.getters["tariffs/types"]);

const type = computed(() => types.value.find((type) => type.id == props.id));

const loading = computed(() => {
  const { id, value } = store.getters["tariffs/loading"];
  return id == "tariffs" && value;
});

const isActive = (letter) => {
  return letter.toLowerCase() == activeLetter.value.toLowerCase();
};

const letters = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

const filteredTariffs = computed(() => {
  let data = tariffs.value;

  if (!data.length) return [];

  if (search.value != "") {
    data = data.filter(({ destination, dialing_code }) => {
      return (
        destination.toLowerCase().includes(search.value) ||
        dialing_code.includes(search.value)
      );
    });
  }

  if (activeLetter.value != "") {
    data = data.filter(({ destination }) => {
      return (
        destination?.toLowerCase()?.charAt(0) ==
        activeLetter.value?.toLowerCase()
      );
    });
  }

  return data;
});

watch(
  route,
  (newRoute) => {
    getTariffs(newRoute.params.id);
  },
  {
    deep: true,
  }
);

onMounted(() => {
  getTariffs(props.id);
});
</script>
